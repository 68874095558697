import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

function SEO({ description, lang, meta, title, image, type, node }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            description
            author
            siteUrl
            siteLanguage
            ogLanguage
            twitter
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const pageImage = image || "";

  const isArticle = type === "article";

  var slug = "";
  if (node && node.fields) {
    slug = node.fields.slug;
  }

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: site.siteMetadata.siteUrl + slug,
    headline: title,
    inLanguage: site.siteMetadata.siteLanguage,
    mainEntityOfPage: site.siteMetadata.siteUrl + slug,
    description: metaDescription,
    name: title,
    author: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    copyrightYear: "2021",
    creator: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    publisher: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    datePublished: "2012-10-01T10:30:00+01:00",
    dateModified: site.buildTime,
  };

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": site.siteMetadata.siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ];

  let schemaArticle = null;
  if (isArticle) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: site.siteMetadata.author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: site.siteMetadata.author,
      },
      copyrightYear: "2021",
      creator: {
        "@type": "Person",
        name: site.siteMetadata.author,
      },
      datePublished: node.fields.date,
      dateModified: node.fields.date,
      description: description,
      headline: title,
      inLanguage: site.siteMetadata.siteLanguage,
      url: site.siteMetadata.siteUrl + slug,
      name: title,
      mainEntityOfPage: site.siteMetadata.siteUrl + slug,
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": site.siteMetadata.siteUrl + slug,
        name: title,
      },
      position: 2,
    });
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.siteUrl + pageImage,
        },
        {
          property: `og:type`,
          content: isArticle ? "article" : "website",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata.siteUrl + pageImage,
        },
      ].concat(meta)}
    >
      {!isArticle && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {isArticle && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>`
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
