import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";

const Header = () => (
  <StaticQuery
    query={graphql`
      {
        headshot: file(relativePath: { eq: "headshot.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 125, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <header className="mx-auto prose dark:prose-a:text-white lg:prose-xl nav pt-4">
        <div className="flex-1 flex-row items-center w-full">
          <div>
            <a
              className="font-bold text-2xl no-underline text-gray-900 dark:text-white"
              href="/"
            >
              &lt; Zac Siegel &gt;
            </a>
            <div className="flex justify-between pt-4 text-white">
              <div>
                <a className="block" href="/articles">
                  Articles
                </a>
              </div>
              <div>
                <a className="block" href="/videos">
                  Videos
                </a>
              </div>
              <div>
                <Link className="block" to="/stuff-i-use" title="Gear">
                  Gear
                </Link>
              </div>
              <div>
                <a
                  className="block"
                  href="https://github.com/zsiegel"
                  title="Github"
                >
                  Github
                </a>
              </div>
              <div>
                <a
                  className="block"
                  href="https://twitter.com/zac_siegel"
                  title="Twitter"
                >
                  Twitter
                </a>
              </div>
              <div>
                <Link className="block" to="/rss.xml" title="RSS">
                  RSS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    )}
  />
);

export default Header;
