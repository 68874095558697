import React from "react";
import "../css/index.css";
import Header from "./header";

const Layout = ({ children }) => (
  <div className="mx-auto md:max-w-3xl md:mx-auto lg:max-w-4xl px-4">
    <Header />
    <main className="py-8">{children}</main>
  </div>
);

export default Layout;
